<template>
	<div class="container">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">我的收藏</div>
		</div>
		<van-list v-model="loading" @load="getProductEntryEndueList" :finished="finished" finished-text="没有更多了"
			:error.sync="error" error-text="请求失败，点击重新加载">
			<div class="item-wrap">
				<div class="item flex-row align-center" v-for="(item,index) in list" :key="index" @click.stop="navShop(index)">
					{{item.shopName}}的古玩店
				</div>
			</div>
		</van-list>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../../config/config.js';
	import util from '../../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished: false,
				error: false
			}
		},
		methods: {
			getProductEntryEndueList() {
				this.loading = true;
				const that = this;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);
				this.axios.post(config.requestUrl + '/front/antiqueShop/getProductEntryEndueList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = this.list;
						let newList = response.data.obj.list;
						if (response.data.obj.isLastPage) {
							this.finished = true;
							this.more = false;
						}
						list = list.concat(newList);
						this.pageNum = this.pageNum + 1;
						this.list = list;
						if (this.list.length == 0) {
							this.empty = true;
						}
						Toast.clear()
						this.$forceUpdate();
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						this.error = true;
					}
					this.loading = false;
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},
			navShop(index){
				window.location.href=config.tdUrl+'/?id='+this.list[index].id
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		background: url('../../assets/shop/bg.jpg');
		background-size: 750px 1448px;
		min-height: 100%;
	}

	.item-wrap {
		margin-top: 30px;
	}

	.item {
		width: 690px;
		height: 150px;
		margin: 14px auto;
		padding-left: 159px;
		box-sizing: border-box;
		background: url('../../assets/shop/c-shop-bg.png');
		background-size: 690px 150px;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}
</style>
